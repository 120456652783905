/* --- login adjustments---  */
/* wrapper */
.o--Widget--widget,
.o--Widget--widget *:not(.material-icons),
.o--Widget--widget *:-webkit-autofill::first-line,
.o--Widget--widget h1,
.o--Widget--widget h2,
.o--Widget--widget h3,
.o--Widget--widget h4,
.o--Widget--widget h5,
.o--Widget--widget h6 {
    font-family: "Sweet sans", Arial, sans-serif !important;
}

.o--Widget--displayMode-light {
    background: transparent !important;
}

.o--Widget--widgetBody {
    padding: 0 !important;
}

/* hide title */
.o--Widget--widgetBody h1 {
    color: #17372e !important;
    text-align: left !important;
    margin-bottom: 1.5em !important;
    font-size: 0.875rem !important;
    line-height: 1.1 !important;
    font-weight: 500 !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
}

/* input adjustments */
.o--FloatLabelInput--hasFloatLabel .o--FloatLabelInput--cornerShape-rounded, 
. o--TextArea--textArea.o--TextArea--cornerShape-rounded,
.o--FloatLabelSelect--formControl.o--FloatLabelSelect--cornerShape-rounded {
    border-radius: 7px !important;
}

.o--FloatLabelInput--hasFloatLabel .o--FloatLabelInput--borderWidth-2px,
.o--TextArea--textArea.o--TextArea--borderWidth-2px,
.o--FloatLabelSelect--formControl.o--FloatLabelSelect--borderWidth-2px {
    border-width: 1px !important;
}

.o--FloatLabelInput--hasFloatLabel .o--FloatLabelInput--fieldStyle-light-border,
.o--TextArea--textArea.o--TextArea--fieldStyle-light-border,
.o--FloatLabelSelect--formControl.o--FloatLabelSelect--fieldStyle-light-border {
    border-color: #17372e !important;
    color: #17372e !important;
}

.o--FloatLabelInput--hasFloatLabel:hover .o--FloatLabelInput--fieldStyle-light-border,
.o--TextArea--textArea.o--TextArea--fieldStyle-light-border:hover,
.o--FloatLabelSelect--formControl.o--FloatLabelSelect--fieldStyle-light-border:hover {
    border-color: #f94e41 !important;
    background-color: rgba(255, 216, 215, 0.32);
}

/* button adjustments */

.o--Button--btn {
    display: block !important;
    width: 100% !important;
    padding: 18px 16px !important;
    -webkit-transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid .o--Button--children {
    color: inherit !important;
    font-family: "Sweet sans", Arial, sans-serif !important;
    font-size: 1rem !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    letter-spacing: 0.08em !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
}

.o--Widget--widget .o--Button--btn.o--Button--cornerShape-rounded {
    border-radius: 7px !important;
}

.o--Widget--widget .o--Button--btn.o--Button--borderWidth-2px {
    border-width: 0 !important;
}

.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid {
    background-color: #f94e41 !important;
    color: #fff !important;
}

.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid:hover {
    background-color: #ffd8d7 !important;
    color: #f94e41 !important;
}

.o--Widget--widget .o--Button--btn[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #ffd8d7 !important;
    color: #f94e41 !important;
}

.state-forgotPassword .o--Button--buttonType-cancel {
    margin-top: 8px !important;
    background-color: #ffd8d7 !important;
    color: #f94e41 !important;
}

.state-forgotPassword .o--Button--buttonType-cancel:hover {
    background-color: #f94e41 !important;
    color: #fff !important;
}

.state-ResetPassword .o--Button--buttonType-cancel {
    margin-top: 8px !important;
    background-color: #ffd8d7 !important;
    color: #f94e41 !important;
}

.state-ResetPassword .o--Button--buttonType-cancel:hover {
    background-color: #f94e41 !important;
    color: #fff !important;
}

/* forgot password */
.o--Widget--widget a {
    color: #f94e41 !important;
}

.state-login div:last-child {
    margin-top: 12px !important;
}

.state-forgotPassword .o--FormGroup--formGroup:last-child {
    margin-bottom: 0;
    display: block !important;
}

/* error message */
.o--Widget--widget .o--ErrorMessages--cornerShape-rounded,
.o--Widget--widget .o--ErrorMessages--cornerShape-pill {
    border-radius: 7px !important;
}

.o--Widget--widget .o--ErrorMessages--errorMessages {
    background-color: transparent !important;
    color: #f94e41 !important;
    margin: 0 0 16px 0 !important;
    padding: 14px 16px !important;
    border: 1px solid #f94e41;
}

.o--Widget--widget .o--ErrorMessages--errorMessages ul li {
    color: #f94e41 !important;
}

/* --- sign up adjustments---  */
.o--Widget--widget .o--PlanSelector--planSelector ul.o--PlanSelector--planList li.o--PlanSelector--planListItem {
    padding: 16px 18px !important;
}

li.o--PlanSelector--planListItem:last-child {
    margin-bottom: 0 !important;
}

.o--Widget--widget .o--SectionGroup--sectionGroup:last-child {
    margin-bottom: 0 !important;
}

.o--Widget--widget .o--PlanSelector--cornerShape-rounded .o--PlanSelector--planList li,
.o--Widget--widget .o--PlanSelector--cornerShape-pill .o--PlanSelector--planList li {
    border-radius: 7px !important;
}

.o--Widget--widget .o--PlanSelector--borderWidth-2px .o--PlanSelector--planList li {
    border-width: 1px !important;
    border-color: #f94e41 !important;
}

.o--Widget--widget .o--PlanSelector--planListItem {
    background-color: #fff !important;
    -webkit-transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.o--Widget--widget .o--PlanSelector--planListItem:hover {
    background-color: #ffd8d7 !important;
}

.o--PlanSelector--planName,
.o--PlanSelector--price {
    font-size: 0.875rem !important;
    line-height: 1.1 !important;
    font-weight: 500 !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
}

/* password confirmation */
#widget-Outseta_auth {
    display: none;
}

.state-ResetPassword .o--FormGroup--formGroup:last-child {
    margin-bottom: 0;
    display: block !important;
}

/* forgot password confirmation */
.state-forgotPasswordConfirmation i {
    font-size: 80px !important;
}

.state-forgotPasswordConfirmation p {
    margin: 16px 0 60px 0 !important;
}

.o--Widget--widget .o--color-accent {
    color: #f84e41;
}

/* --- profile adjustments --- */
.o--App--profileWidget .o--App--widgetBody {
    margin: 0 !important;
}

.o--Widget--widget.o--App--profileWidget {
    max-width: none !important;
}

.o--Widget--widget.o--App--profileWidget.o--Widget--displayMode-light {
    background: transparent !important;
}

.o--App--profileWidget .o--Button--btn {
    width: auto !important;
    padding: 12px 16px !important;
}

.o--App--profileWidget .o--App--widgetBody .o--App--widgetContent {
    padding: 8px 0 40px 40px !important;
}

@media only screen and (min-width: 600px) {
    .o--Widget--widget .o--NavDesktop--navDesktop {
        padding: 0 40px 0 0 !important;
    }
}

.o--Widget--widget .o--StickyActionRow--stickyActionRow.o--StickyActionRow--displayMode-light {
    background: transparent !important;
    border-color: transparent !important;
    padding-top: 0 !important;
}

.o--Widget--widget .o--StickyActionRow--stickyActionRow.o--StickyActionRow--forceRow,
.o--Widget--widget .o--StickyActionRow--stickyActionRow {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.o--StickyActionRow--stickyActionRow .o--Button--btn:first-child {
    margin-right: 16px;
}

.o--Profile--notification-settings .o--Person-PartyEmailOptOut {
    display: none;   
}

.o--Profile--notification-settings .o--Person-PartySMSOptOut {
    display: none;   
}

/* menu adjustments */
.o--NavDesktop--navDesktop ul li a {
    color: #17372e !important;
    border-radius: 7px !important;
    padding: 14px 20px !important;
    font-size: 1rem !important;
    line-height: 1.1 !important;
    font-weight: 500 !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
}

.o--NavDesktop--navDesktop ul li.o--NavDesktop--active a {
    background-color: #17372e !important;
    color: #fff !important;
}

.o--NavDesktop--navDesktop.o--NavDesktop--cornerShape-rounded li:before {
    background-color: transparent !important;
}

/* input adjustments */
.o--Widget--widget .o--Input--cornerShape-rounded {
    border-radius: 7px !important;
}

.o--Widget--widget .o--Input--borderWidth-2px {
    border-width: 1px !important;
}

.o--Widget--widget .o--Input--fieldStyle-light-border {
    border-color: #17372e !important;
    color: #17372e !important;
}

.o--Widget--widget .o--Input--fieldStyle-light-border:hover {
    border-color: #f94e41 !important;
    background-color: rgba(255, 216, 215, 0.32);
}

/* credit card input adjustments */
.o--Widget--widget .o--PaymentInputStripe--stripeCornerShape-rounded {
    border-radius: 7px !important;
}

.o--Widget--widget .o--PaymentInputStripe--stripeBorderWidth-2px {
    border-width: 1px !important;
}

.o--Widget--widget .o--PaymentInputStripe--stripeFieldStyle-light-border {
    border-color: #17372e !important;
    color: #17372e !important;
}

.o--Widget--widget .o--PaymentInputStripe--stripeFieldStyle-light-border:hover {
    border-color: #f94e41 !important;
    background-color: rgba(255, 216, 215, 0.32);
}

.ElementsApp,
.ElementsApp .InputElement {
    color: #17372e !important;
    font-family: "Sweet sans", Arial, sans-serif !important;
    font-size: 1rem !important;
}

.o--Profile--parties.o--SectionGroup--sectionGroup {
    display: none;
}

.o--Account--parties.o--SectionGroup--sectionGroup {
    display: none;
}

/* Support popup */
.o--Widget--cornerShape-rounded, .o--Widget--cornerShape-pill {
    border-radius: 7px !important;
}
.o--Widget--widget.o--App--supportWidget {
    background-color: #fff8ec !important;
}
.o--App--supportWidget .o--Widget--widgetBody {
    padding: 24px !important;
}

.o--App--supportWidget .o--Widget--widgetBody h1 {
    text-align: left!important;
    margin-bottom: 0!important;
    font-size: 2.5rem!important;
    line-height: 1.1!important;
    font-weight: 400!important;
    letter-spacing: -0.01em!important;
    text-transform: none!important;
    font-family: Ivypresto,Georgia,sans-serif !important;
    padding-right: 28px !important;
}
